import React from "react"
import { graphql } from "gatsby"
import Fingerprint2 from "fingerprintjs2"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import SaveIcon from "@material-ui/icons/Save"
import Skeleton from "@material-ui/lab/Skeleton"
import Typography from "@material-ui/core/Typography"
import { CSVLink } from "react-csv"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import ToolLayout from "../../../components/tool_layout"

export const query = graphql`
  query WebFingerprintQuery {
    allToolsJson(filter: { path: { eq: "web/fingerprint" } }) {
      edges {
        node {
          path
          title
          description
          links {
            mdn
            github
          }
        }
      }
    }
  }
`

class FingerPrintState {
  components: Map<string, any> = new Map()
}

class FingerPrintPage extends React.Component<{ data: any }> {
  state: FingerPrintState

  constructor(props: any) {
    super(props)

    this.state = new FingerPrintState()
  }

  componentDidMount() {
    Fingerprint2.get(components => {
      this.setState({
        components: components,
      })
    })
  }

  featuresTable = () => {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Feature</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(this.state.components).map(entry => (
              <TableRow key={entry[0]} hover>
                <TableCell>
                  <Typography variant="subtitle2">{entry[1].key}</Typography>
                </TableCell>
                <TableCell>
                  <Box display="flex">
                    <Typography variant="body2" noWrap>
                      {entry[1].value.toString()}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  render() {
    if (this.state.components.size === 0) {
      return (
        <ToolLayout data={this.props.data}>
          {Array.from(Array(10).keys()).map(index => (
            <Skeleton animation="wave" key={index} />
          ))}
        </ToolLayout>
      )
    }

    return (
      <ToolLayout data={this.props.data}>
        <Box
          width={1}
          mt={2}
          mb={2}
          mr={8}
          display="flex"
          flexDirection="row-reverse"
        >
          <CSVLink data={this.state.components} filename="fingerprint.csv">
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
            >
              Export to CSV
            </Button>
          </CSVLink>
        </Box>
        <Paper>{this.featuresTable()}</Paper>
      </ToolLayout>
    )
  }
}

export default FingerPrintPage
